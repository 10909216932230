export const LANGUAGE_MAP = {
  "/uk": "uk",
  "/es": "es",
  "/ar": "es",
  "/cl": "es",
};

export const getLanguageFromUrl = (pathname) => {
  const path = pathname || window.location.pathname;
  return (
    LANGUAGE_MAP[
      Object.keys(LANGUAGE_MAP).find((key) => path.startsWith(key))
    ] ?? "en"
  );
};

export const getPathWithoutLanguage = (pathname) => {
  const path = pathname || window.location.pathname;
  const key = Object.keys(LANGUAGE_MAP).find((key) => path.startsWith(key));
  return key ? path.slice(key.length) || "/" : path;
};
