import { push, ref, remove, set, update } from "firebase/database";
import { message } from "antd";
import { getUserId } from "../../helpers";
import { database } from "../../configs";

export default class Categories {
  async create(establishmentID, sectionID, fieldParams, formatMessage) {
    try {
      const userID = await getUserId();
      const createListRef = ref(
        database,
        `${userID}/Establishments/${establishmentID}/Categories`,
      );
      const newCreateRef = push(createListRef);

      await set(newCreateRef, {
        id: newCreateRef.key,
        section_id: sectionID,
        category_active: true,
        ...fieldParams,
      });

      message.success(
        formatMessage({ id: "admin.menu.categories.create.success" }),
      );
    } catch (error) {
      message.error(
        formatMessage({ id: "admin.menu.categories.create.error" }),
      );
    }
  }

  async update(establishmentID, id, fieldParams, formatMessage) {
    try {
      const userID = await getUserId();
      const updateListRef = ref(
        database,
        `${userID}/Establishments/${establishmentID}/Categories/${id}`,
      );

      await update(updateListRef, fieldParams);

      message.success(
        formatMessage({ id: "admin.menu.categories.update.success" }),
      );
    } catch (error) {
      message.error(
        formatMessage({ id: "admin.menu.categories.update.error" }),
      );
    }
  }

  async delete(establishmentID, id, hasSingleDelete, formatMessage) {
    try {
      const userID = await getUserId();
      const deleteListRef = ref(
        database,
        `${userID}/Establishments/${establishmentID}/Categories/${id}`,
      );

      await remove(deleteListRef);

      if (hasSingleDelete) {
        message.success(
          formatMessage({ id: "admin.menu.categories.delete.success" }),
        );
      }
    } catch (error) {
      if (hasSingleDelete) {
        message.error(
          formatMessage({ id: "admin.menu.categories.delete.error" }),
        );
      }
    }
  }

  async activateDeactivate(
    establishmentID,
    id,
    activateDeactivateParams,
    formatMessage,
  ) {
    try {
      const userID = await getUserId();
      const activateDeactivateListRef = ref(
        database,
        `${userID}/Establishments/${establishmentID}/Categories/${id}`,
      );

      await update(activateDeactivateListRef, activateDeactivateParams);

      message.success(
        formatMessage({
          id: "admin.menu.categories.activateDeactivate.success",
        }),
      );
    } catch (error) {
      message.error(
        formatMessage({ id: "admin.menu.categories.activateDeactivate.error" }),
      );
    }
  }

  async reorder(establishmentID, updatedItems, formatMessage) {
    try {
      const userID = await getUserId();
      const createListRef = ref(
        database,
        `${userID}/Establishments/${establishmentID}/Categories`,
      );

      await update(createListRef, updatedItems);

      message.success(
        formatMessage({ id: "admin.menu.categories.update.success" }),
      );
    } catch (error) {
      message.error(
        formatMessage({ id: "admin.menu.categories.update.error" }),
      );
    }
  }
}
