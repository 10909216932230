import antdUk from "antd/es/locale/uk_UA";
import ukMsg from "../locales/uk";

const UkLang = {
  antd: antdUk,
  locale: "uk",
  messages: {
    ...ukMsg,
  },
};

export default UkLang;
