import { makeObservable, observable, action } from "mobx";
import { onValue, push, ref, set, update, remove } from "firebase/database";
import { message } from "antd";
import { getUserId } from "../../helpers";
import { database } from "../../configs";

export default class Establishments {
  mainStore;

  list = null;

  constructor(mainStore) {
    makeObservable(this, {
      list: observable,
      setList: action,
    });

    this.mainStore = mainStore;
  }

  async index() {
    const userID = await getUserId();
    const refValue = ref(database, `${userID}/Establishments`);

    await onValue(refValue, (items) => {
      const dataEstablishments = [];

      items.forEach((item) => {
        const data = item.val();
        dataEstablishments.push({
          ...data,
        });
      });
      this.setList(dataEstablishments);
    });
  }

  async create(value, formatMessage) {
    try {
      const userID = await getUserId();
      const postListRef = ref(database, `${userID}/Establishments`);
      const newPostRef = push(postListRef);

      await set(newPostRef, {
        id: newPostRef.key,
        establishment_name: value,
      });

      message.success(formatMessage({ id: "establishment.created" }));
    } catch (error) {
      message.error(formatMessage({ id: "establishment.create.error" }));
    }
  }

  async update(id, fieldParams, formatMessage) {
    try {
      const userID = await getUserId();
      const postListRef = ref(database, `${userID}/Establishments/${id}`);

      await update(postListRef, fieldParams);

      message.success(formatMessage({ id: "establishment.updated" }));
    } catch (error) {
      message.error(formatMessage({ id: "establishment.update.error" }));
    }
  }

  async delete(id, formatMessage) {
    try {
      const userID = await getUserId();
      const deleteListRef = ref(database, `${userID}/Establishments/${id}`);

      await remove(deleteListRef);

      message.success(formatMessage({ id: "establishment.deleted" }));
    } catch (error) {
      message.error(formatMessage({ id: "establishment.remove.error" }));
    }
  }

  setList(value) {
    this.list = value || null;
  }

  cleanup() {
    this.setList(null);
  }
}
