import antdEs from "antd/es/locale/es_ES";
import esMsg from "../locales/es";

const EsLang = {
  antd: antdEs,
  locale: "es",
  messages: {
    ...esMsg,
  },
};

export default EsLang;
