import adminPanel from "./adminPanel.json";
import auth from "./auth.json";
import menu from "./menu.json";

const esMsg = {
  ...adminPanel,
  ...auth,
  ...menu,
};

export default esMsg;
