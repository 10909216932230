import { push, ref, remove, set, update } from "firebase/database";
import { message } from "antd";
import { database } from "../../configs";
import { getUserId } from "../../helpers";

export default class Products {
  async create(establishmentID, data, formatMessage) {
    try {
      const userID = await getUserId();
      const createListRef = ref(
        database,
        `${userID}/Establishments/${establishmentID}/Products`,
      );
      const newCreateRef = push(createListRef);

      await set(newCreateRef, {
        id: newCreateRef.key,
        ...data,
      });

      message.success(formatMessage({ id: "admin.menu.product.created" }));
    } catch (error) {
      message.error(formatMessage({ id: "admin.menu.product.create.error" }));
    }
  }

  async update(establishmentID, ProductID, fieldParams, formatMessage) {
    try {
      const userID = await getUserId();
      const updateListRef = ref(
        database,
        `${userID}/Establishments/${establishmentID}/Products/${ProductID}`,
      );

      await update(updateListRef, fieldParams);

      message.success(formatMessage({ id: "admin.menu.product.updated" }));
    } catch (error) {
      message.error(formatMessage({ id: "admin.menu.product.update.error" }));
    }
  }

  async delete(establishmentID, ProductID, hasSingleDelete, formatMessage) {
    try {
      const userID = await getUserId();
      const deleteListRef = ref(
        database,
        `${userID}/Establishments/${establishmentID}/Products/${ProductID}`,
      );

      await remove(deleteListRef);

      if (hasSingleDelete) {
        message.success(formatMessage({ id: "admin.menu.product.deleted" }));
      }
    } catch (error) {
      if (hasSingleDelete) {
        message.error(formatMessage({ id: "admin.menu.product.delete.error" }));
      }
    }
  }

  async activateDeactivate(
    establishmentID,
    ProductID,
    activateDeactivateParams,
    formatMessage,
  ) {
    try {
      const userID = await getUserId();
      const activateDeactivateListRef = ref(
        database,
        `${userID}/Establishments/${establishmentID}/Products/${ProductID}`,
      );

      await update(activateDeactivateListRef, activateDeactivateParams);

      message.success(formatMessage({ id: "admin.menu.product.updated" }));
    } catch (error) {
      message.error(formatMessage({ id: "admin.menu.product.update.error" }));
    }
  }

  async reorder(establishmentID, updatedItems, formatMessage) {
    try {
      const userID = await getUserId();
      const createListRef = ref(
        database,
        `${userID}/Establishments/${establishmentID}/Products`,
      );

      await update(createListRef, updatedItems);

      message.success(formatMessage({ id: "admin.menu.product.reordered" }));
    } catch (error) {
      message.error(formatMessage({ id: "admin.menu.product.reorder.error" }));
    }
  }
}
