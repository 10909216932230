export const levelSpicyOptions = [
  { value: "mild" },
  { value: "medium" },
  { value: "hot" },
];

export const spicyLevelMap = {
  mild: 1,
  medium: 2,
  hot: 3,
};

export const allergensOptions = [{ value: "lactose" }, { value: "gluten" }];
