import {
  ADMIN_PANEL,
  ADMIN_PANEL_PREFIX_PATH,
  LANGUAGES,
  MENU,
} from "../constants";
import { MENU_PREFIX_PATH } from "../features/menu/constants";

const MODULE_URLS = {
  [ADMIN_PANEL]: LANGUAGES.map((lang) => `${lang}${ADMIN_PANEL_PREFIX_PATH}`),
  [MENU]: MENU_PREFIX_PATH,
};

export function getFeatureFromRoute(location) {
  return (
    Object.keys(MODULE_URLS).find((module) => {
      const paths = Array.isArray(MODULE_URLS[module])
        ? MODULE_URLS[module]
        : [MODULE_URLS[module]];
      return paths.some((path) => location.pathname.startsWith(path));
    }) || null
  );
}
