import React, { useContext } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { StoreContext } from "../contexts/Store";
import { observer } from "mobx-react";
import { connect } from "react-redux";
import AppLocale from "lang";
import { IntlProvider } from "react-intl";
import { ConfigProvider } from "antd";
import { Auth } from "./auth";
import { AUTH_PREFIX_PATH } from "./auth/constants";
import { AdminPanel } from "./admin-panel";
import { ADMIN_PANEL_PREFIX_PATH } from "../constants";
import { useBodyClass } from "../hooks";
import { Menu } from "./menu";
import { MENU_PREFIX_PATH } from "./menu/constants";
import { THEME_CONFIG } from "../configs";
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import { HomeView, OfferAgreement } from "./home-view";
import { OFFER_AGREEMENT_PATH } from "./home-view/constants";
import { generateLocalizedPaths } from "../helpers/generateLocalizedPaths";
import { getLanguageFromUrl } from "../helpers/languageUtils";

const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};

export const FeaturesNavigation = (props) => {
  // Import MobX stores
  const mainStore = useContext(StoreContext);

  // Props
  const { location, direction } = props;

  // Variables
  const { lang } = mainStore.settings;
  const currentLang = lang ?? getLanguageFromUrl(location.pathname);
  const currentAppLocale = AppLocale[currentLang];

  // Hooks
  useBodyClass(`dir-${direction}`);

  return (
    <IntlProvider
      locale={currentAppLocale.locale}
      messages={currentAppLocale.messages}
    >
      <ConfigProvider locale={currentAppLocale.antd} direction={direction}>
        <Switch>
          <Route exact path={generateLocalizedPaths()}>
            <HomeView />
          </Route>
          <Route exact path={OFFER_AGREEMENT_PATH}>
            <OfferAgreement />
          </Route>
          <Route path={MENU_PREFIX_PATH}>
            <Menu />
          </Route>
          <ThemeSwitcherProvider
            themeMap={themes}
            defaultTheme={THEME_CONFIG.currentTheme}
            insertionPoint="styles-insertion-point"
          >
            <Route path={generateLocalizedPaths(AUTH_PREFIX_PATH)}>
              <Auth />
            </Route>
            <Route path={generateLocalizedPaths(ADMIN_PANEL_PREFIX_PATH)}>
              <AdminPanel direction={direction} location={location} />
            </Route>
          </ThemeSwitcherProvider>
        </Switch>
      </ConfigProvider>
    </IntlProvider>
  );
};

const mapStateToProps = ({ theme }) => {
  const { direction } = theme;
  return { direction };
};

export default withRouter(
  connect(mapStateToProps)(observer(FeaturesNavigation)),
);
