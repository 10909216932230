import { signOut } from "firebase/auth";
import { message } from "antd";
import { SIGN_IN_PATH } from "../features/auth/constants";
import { authentication } from "../configs";
import { getLocalizedPath } from "./getLocalizedPath";

export const logOut = async (history) => {
  try {
    await signOut(authentication);
    history.push(getLocalizedPath(SIGN_IN_PATH));
  } catch (error) {
    message.error("При виході виникла помилка");
  }
};
