import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import {
  ADMIN_PANEL_ESTABLISHMENT_PATH,
  ADMIN_PANEL_MENU_PATH,
  ADMIN_PANEL_SUBSCRIPTION_SETTINGS_PATH,
  ADMIN_PANEL_PREFIX_PATH,
  ADMIN_PANEL_PRICING_PATH,
  ADMIN_PANEL_SETTINGS_PATH,
} from "../../constants";
import { Loading } from "../../components/shared-components";
import { generateLocalizedPaths } from "../../helpers/generateLocalizedPaths";
import { getLocalizedPath } from "../../helpers";

export const AdminPanelNavigation = () => {
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route
          path={generateLocalizedPaths(ADMIN_PANEL_PRICING_PATH)}
          component={lazy(() => import(`./pricing/Pricing.js`))}
        />
        <Route
          path={generateLocalizedPaths(ADMIN_PANEL_SUBSCRIPTION_SETTINGS_PATH)}
          component={lazy(
            () => import(`./subscription-settings/SubscriptionSettings.js`),
          )}
        />
        <Route
          path={generateLocalizedPaths(ADMIN_PANEL_SETTINGS_PATH)}
          component={lazy(() => import(`./settings/Settings.js`))}
        />
        <Route
          path={generateLocalizedPaths(ADMIN_PANEL_ESTABLISHMENT_PATH)}
          component={lazy(() => import(`./establishments/Establishments.js`))}
        />
        <Route
          path={generateLocalizedPaths(ADMIN_PANEL_MENU_PATH)}
          component={lazy(() => import(`./menu-settings/MenuNavigation.js`))}
        />
        <Redirect
          from={getLocalizedPath(ADMIN_PANEL_PREFIX_PATH)}
          to={getLocalizedPath(ADMIN_PANEL_ESTABLISHMENT_PATH)}
        />
      </Switch>
    </Suspense>
  );
};

export default React.memo(AdminPanelNavigation);
