import antdEn from "antd/es/locale/en_US";
import enMsg from "../locales/en";

const EnLang = {
  antd: antdEn,
  locale: "en",
  messages: {
    ...enMsg,
  },
};

export default EnLang;
