import React, { useContext, useEffect, useState } from "react";
import { StoreContext } from "../../../../../contexts/Store";
import { observer } from "mobx-react";
import { useMediaQuery } from "react-responsive";
import classnames from "classnames";
import throttle from "lodash/throttle";
import { BoxWrapper } from "../../../shared-components";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

function MenuScrollToTop() {
  // Import MobX stores
  const mainStore = useContext(StoreContext);

  // State
  const [isVisible, setIsVisible] = useState(false);

  // Variables
  const { selectedProducts, favoriteProducts } = mainStore.menu;
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const isDelivery = false; // TODO: temporary value, need to update after implementing delivery or order functionality
  const chosenProducts = isDelivery ? selectedProducts : favoriteProducts;

  // Effects
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Functions
  function handleClick() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  const handleScroll = throttle(() => {
    if (window.scrollY > 100) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  }, 166);

  return (
    isVisible && (
      <BoxWrapper
        className={classnames("tw-fixed", "tw-bottom-5", "tw-right-4", {
          "!tw-bottom-16": isMobile && !!chosenProducts.length,
        })}
        onClick={handleClick}
      >
        <ArrowUpwardIcon className="!tw-text-2xl" />
      </BoxWrapper>
    )
  );
}

export default observer(MenuScrollToTop);
