import { push, ref, remove, set, update } from "firebase/database";
import { message } from "antd";
import { getUserId } from "../../helpers";
import { database } from "../../configs";

export default class Sections {
  async create(value, establishmentID, formatMessage) {
    try {
      const userID = await getUserId();
      const createListRef = ref(
        database,
        `${userID}/Establishments/${establishmentID}/Sections`,
      );
      const newCreateRef = push(createListRef);

      await set(newCreateRef, {
        id: newCreateRef.key,
        section_name: value,
        section_active: true,
      });

      message.success(formatMessage({ id: "admin.menu.sections.created" }));
    } catch (error) {
      message.error(formatMessage({ id: "admin.menu.sections.create.error" }));
    }
  }

  async update(establishmentID, id, fieldParams, formatMessage) {
    try {
      const userID = await getUserId();
      const updateListRef = ref(
        database,
        `${userID}/Establishments/${establishmentID}/Sections/${id}`,
      );

      await update(updateListRef, fieldParams);

      message.success(formatMessage({ id: "admin.menu.sections.updated" }));
    } catch (error) {
      message.error(formatMessage({ id: "admin.menu.sections.update.error" }));
    }
  }

  async delete(establishmentID, id, formatMessage) {
    try {
      const userID = await getUserId();
      const deleteListRef = ref(
        database,
        `${userID}/Establishments/${establishmentID}/Sections/${id}`,
      );

      await remove(deleteListRef);

      message.success(formatMessage({ id: "admin.menu.sections.deleted" }));
    } catch (error) {
      message.error(formatMessage({ id: "admin.menu.sections.delete.error" }));
    }
  }

  async activateDeactivate(
    establishmentID,
    id,
    activateDeactivateParams,
    formatMessage,
  ) {
    try {
      const userID = await getUserId();
      const activateDeactivateListRef = ref(
        database,
        `${userID}/Establishments/${establishmentID}/Sections/${id}`,
      );

      await update(activateDeactivateListRef, activateDeactivateParams);

      message.success(formatMessage({ id: "admin.menu.sections.updated" }));
    } catch (error) {
      message.error(formatMessage({ id: "admin.menu.sections.update.error" }));
    }
  }

  async reorder(establishmentID, updatedItems, formatMessage) {
    try {
      const userID = await getUserId();
      const createListRef = ref(
        database,
        `${userID}/Establishments/${establishmentID}/Sections`,
      );

      await update(createListRef, updatedItems);

      message.success(formatMessage({ id: "admin.menu.sections.reordered" }));
    } catch (error) {
      message.error(formatMessage({ id: "admin.menu.sections.reorder.error" }));
    }
  }
}
