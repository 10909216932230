import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import {
  AUTH_PREFIX_PATH,
  SIGN_IN_PATH,
  SIGN_UP_PATH,
  RESET_PASSWORD_PATH,
} from "./constants";
import { Loading } from "../../components/shared-components";
import { generateLocalizedPaths } from "../../helpers/generateLocalizedPaths";

function AuthNavigation() {
  const LoginEmail = lazy(() => import(`./components/login-email/LoginEmail`));
  const RegisterEmail = lazy(
    () => import(`./components/register-email/RegisterEmail`),
  );
  const ResetPassword = lazy(
    () => import(`./components/reset-password/ResetPassword`),
  );

  return (
    <Suspense fallback={<Loading cover="page" />}>
      <Switch>
        <Route path={generateLocalizedPaths(SIGN_IN_PATH)}>
          <LoginEmail />
        </Route>
        <Route path={generateLocalizedPaths(SIGN_UP_PATH)}>
          <RegisterEmail />
        </Route>
        <Route path={generateLocalizedPaths(RESET_PASSWORD_PATH)}>
          <ResetPassword />
        </Route>
        <Redirect
          from={generateLocalizedPaths(AUTH_PREFIX_PATH)}
          to={generateLocalizedPaths(SIGN_IN_PATH)}
        />
      </Switch>
    </Suspense>
  );
}

export default AuthNavigation;
