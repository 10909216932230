import { Button, Form, Input, Modal } from "antd";
import React from "react";
import { PlusCircleOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import TextArea from "antd/lib/input/TextArea";
import { useIntl } from "react-intl";

function ModalToCreateEdit(props) {
  const {
    customTrigger,
    triggerButtonText,
    showModal,
    title,
    visible,
    onCancel,
    onSubmit,
    onChange,
    label,
    name,
    form,
    placeholder,
    rules,
    fields,
    onChangeDescription,
    labelDescription,
    placeholderDescription,
  } = props;
  const { formatMessage } = useIntl();
  const hasDescription = fields.some((field) => field.name === "description");

  return (
    <>
      {!!customTrigger ? (
        customTrigger
      ) : (
        <Button
          type="primary"
          onClick={showModal}
          icon={<PlusCircleOutlined />}
        >
          {triggerButtonText}
        </Button>
      )}
      <Modal
        title={title}
        visible={visible}
        onCancel={onCancel}
        footer={[
          <Button onClick={onCancel}>
            {formatMessage({ id: "common.close" })}
          </Button>,
          <Button type="primary" form={form} htmlType="submit">
            {formatMessage({ id: "common.create" })}
          </Button>,
        ]}
      >
        <Form
          onFinish={onSubmit}
          id={form}
          fields={fields}
          labelCol={{ span: 7 }}
          wrapperCol={{ span: 17 }}
        >
          <Form.Item
            label={label}
            name={name}
            rules={rules}
            style={{ marginBottom: hasDescription ? 12 : 0 }}
          >
            <Input
              onKeyDown={(event) => event.stopPropagation()}
              placeholder={placeholder}
              onChange={onChange}
            />
          </Form.Item>
          {hasDescription && (
            <Form.Item
              label={labelDescription}
              name="description"
              style={{ marginBottom: 0 }}
            >
              <TextArea
                onKeyDown={(event) => event.stopPropagation()}
                placeholder={placeholderDescription}
                onChange={onChangeDescription}
              />
            </Form.Item>
          )}
        </Form>
      </Modal>
    </>
  );
}

ModalToCreateEdit.propTypes = {
  customTrigger: PropTypes.object,
  triggerButtonText: PropTypes.string,
  showModal: PropTypes.func,
  title: PropTypes.string,
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  fields: PropTypes.array,
  rules: PropTypes.array,
  onChangeDescription: PropTypes.func,
  labelDescription: PropTypes.string,
  placeholderDescription: PropTypes.string,
};

ModalToCreateEdit.defaultProps = {
  title: "",
  placeholder: "",
  fields: [],
  rules: [],
};

export default ModalToCreateEdit;
