import enLang from "./entries/en";
import ukLang from "./entries/uk";
import esLang from "./entries/es";

const AppLocale = {
  uk: ukLang,
  en: enLang,
  es: esLang,
};

export default AppLocale;
