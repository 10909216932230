import React, { useContext, useState } from "react";
import { StoreContext } from "../../../../../contexts/Store";
import { observer } from "mobx-react";
import Button from "@mui/material/Button";
import {
  Box,
  Container,
  Modal,
  SwipeableDrawer,
  Typography,
} from "@mui/material";
import { MenuProduct } from "../menu-products/menu-product";
import { useMediaQuery } from "react-responsive";
import classnames from "classnames";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import { CONTAINER_MAX_WIDTH } from "../../../constants";
import { MenuDivider } from "../../../shared-components";
import { IntlMessage } from "../../../../../components/util-components";

function MenuSelectedProducts() {
  // Import MobX stores
  const mainStore = useContext(StoreContext);

  // State
  const [openModal, setOpenModal] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);

  // Variables
  const isTabletOrLarger = useMediaQuery({ query: "(min-width: 768px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const { data, selectedProducts, isThemeDark, mainColor, favoriteProducts } =
    mainStore.menu;
  const isDelivery = false; // TODO: temporary value, need to update after implementing delivery or order functionality
  const chosenProducts = isDelivery ? selectedProducts : favoriteProducts;
  const products = Object.values(data?.Products ?? {}).sort(
    (a, b) => a.position - b.position,
  );
  const filteredSelectedProducts = products.filter((product) => {
    return selectedProducts.some(
      (selectedProduct) => selectedProduct.id === product.id,
    );
  });
  const filteredFavoriteProducts = products.filter((product) =>
    chosenProducts.includes(product.id),
  );
  const filteredProducts = isDelivery
    ? filteredSelectedProducts
    : filteredFavoriteProducts;

  const productPortionsAmounts = selectedProducts.map((product) => {
    return Object.values(product.portions)
      .filter((portion) => !!portion.modifiedPrice)
      .reduce((total, portion) => total + portion.modifiedPrice, 0);
  });
  const totalAmount = productPortionsAmounts.reduce(
    (total, portion) => total + portion,
    0,
  );
  const firstNonEmptyUnitsForPrice = filteredProducts
    .flatMap((product) => product.portions)
    ?.find(
      (portion) =>
        portion?.units_for_price && portion?.units_for_price.trim() !== "",
    )?.units_for_price;

  const modalStyle =
    "tw-flex tw-flex-col tw-pt-8 tw-pb-3 tw-px-2 tw-rounded-lg tw-absolute tw-top-1/2 tw-left-1/2 tw-transform tw-translate-x-[-50%] tw-translate-y-[-50%] tw-w-11/12 tw-max-w-4xl tw-max-h-[80vh]";

  // Functions
  function handleClick(event) {
    if (isTabletOrLarger) {
      setOpenModal(true);
    } else {
      toggleDrawer(true, event);
    }
  }

  function toggleDrawer(open, event) {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpenDrawer(open);
  }

  function handleClose() {
    setOpenModal(false);
  }

  function handleDeleteProducts() {
    isDelivery
      ? mainStore.menu.setSelectedProducts([])
      : mainStore.menu.setFavoriteProducts([]);
  }

  // Elements
  const totalAmountSection = (
    <Container maxWidth={CONTAINER_MAX_WIDTH}>
      {isDelivery && (
        <Typography
          component="h3"
          className="!tw-text-xl !tw-font-bold tw-py-4 tw-uppercase"
        >
          <IntlMessage id="menu.totalAmount" />:{" "}
          <Typography
            className="!tw-text-xl !tw-font-bold tw-uppercase"
            style={{ color: mainColor }}
            component="span"
          >
            {totalAmount} {firstNonEmptyUnitsForPrice}
          </Typography>
        </Typography>
      )}
      <Button
        className={classnames(
          "tw-bottom-0 tw-inset-x-0 !tw-font-bold tw-w-full !tw-mx-auto !tw-mb-5 !tw-bg-transparent",
          {
            "!tw-mt-5 ": !isDelivery,
          },
        )}
        style={{ borderColor: mainColor, color: mainColor }}
        variant="outlined"
        onClick={handleDeleteProducts}
        endIcon={<DeleteIcon />}
        disableFocusRipple
        disableRipple
      >
        <IntlMessage id="menu.deleteAll" />
      </Button>
    </Container>
  );

  return (
    <>
      <Button
        className={classnames(
          "!tw-fixed",
          "!tw-font-bold",
          "!tw-text-white",
          "tw-min-h-11",
          "!tw-rounded-t-2xl",
          "!tw-rounded-b-none",
          "tw-bottom-0",
          "tw-inset-x-0",
          "tw-w-full",
          "!tw-mx-auto",
          "!tw-transition-none",
          {
            "tw-max-w-lg": isTabletOrLarger,
          },
        )}
        style={{ backgroundColor: mainColor }}
        variant="contained"
        onClick={(event) => handleClick(event)}
        disableFocusRipple
        disableRipple
      >
        <IntlMessage id="menu.checkSelectedProducts" />
      </Button>
      {isTabletOrLarger && (
        <Modal open={openModal} onClose={handleClose}>
          <Box
            sx={{
              bgcolor: "background.paper",
              boxShadow: 24,
            }}
            className={modalStyle}
          >
            <Box className="tw-flex tw-flex-initial">
              <Container maxWidth={CONTAINER_MAX_WIDTH}>
                <Box
                  className={classnames(
                    "tw-flex",
                    "tw-justify-between",
                    "tw-gap-2.5",
                    "tw-py-5",
                    "tw-border-solid",
                    "tw-rounded-t-md",
                    "tw-border",
                    "tw-px-4",
                    {
                      "tw-bg-neutral-50 tw-border-neutral-300": !isThemeDark,
                      "tw-bg-neutral-950 tw-border-neutral-600": isThemeDark,
                    },
                  )}
                >
                  <Typography
                    component="h2"
                    className="!tw-text-2xl !tw-font-bold tw-truncate tw-uppercase"
                  >
                    <IntlMessage id="menu.selectedProducts" />
                  </Typography>
                  <CloseIcon
                    className="tw-cursor-pointer"
                    onClick={handleClose}
                  />
                </Box>
              </Container>
            </Box>
            <Box className="tw-flex-auto tw-overflow-y-auto">
              {filteredProducts.map((product, index) => {
                return (
                  <MenuProduct
                    key={product.id}
                    {...product}
                    className={classnames({
                      "tw-border-b-0": index === filteredProducts.length - 1,
                    })}
                    selectedProduct={selectedProducts.find(
                      (selectedProduct) => selectedProduct.id === product.id,
                    )}
                  />
                );
              })}
            </Box>
            <Container maxWidth={CONTAINER_MAX_WIDTH}>
              <Box
                className={classnames(
                  "tw-border",
                  "tw-border-t-0",
                  "tw-border-solid",
                  {
                    "tw-border-neutral-300": !isThemeDark,
                    "tw-border-neutral-600": isThemeDark,
                  },
                )}
              />
            </Container>
            <Box className="tw-mt-auto tw-flex-initial">
              {totalAmountSection}
            </Box>
          </Box>
        </Modal>
      )}
      {isMobile && (
        <SwipeableDrawer
          anchor="bottom"
          open={openDrawer}
          onOpen={() => toggleDrawer(true)}
          onClose={() => toggleDrawer(false)}
          swipeAreaWidth={0}
        >
          <Box
            className={classnames("tw-h-[80vh] tw-flex tw-flex-col", {
              "tw-bg-neutral-925": isThemeDark,
            })}
          >
            <Box
              className={classnames("tw-flex-initial", {
                "tw-bg-neutral-50": !isThemeDark && isMobile,
                "tw-bg-neutral-950": isThemeDark && isMobile,
              })}
            >
              <MenuDivider />
              <Container maxWidth={CONTAINER_MAX_WIDTH}>
                <Box className="tw-flex tw-justify-between tw-gap-2.5">
                  <Typography
                    component="h2"
                    className="!tw-text-2xl !tw-font-bold tw-truncate tw-py-5 tw-uppercase"
                  >
                    <IntlMessage id="menu.selectedProducts" />
                  </Typography>
                  <CloseIcon
                    className="tw-mt-3.5 tw-cursor-pointer"
                    onClick={() => toggleDrawer(false)}
                  />
                </Box>
              </Container>
              <MenuDivider />
            </Box>
            <Box className="tw-flex-auto tw-overflow-y-auto">
              {filteredProducts.map((product, index) => {
                return (
                  <MenuProduct
                    key={product.id}
                    {...product}
                    isLast={index === filteredProducts.length - 1}
                    selectedProduct={selectedProducts.find(
                      (selectedProduct) => selectedProduct.id === product.id,
                    )}
                  />
                );
              })}
            </Box>
            <Box className="tw-mt-auto tw-flex-initial">
              <MenuDivider />
              {totalAmountSection}
              <MenuDivider />
            </Box>
          </Box>
        </SwipeableDrawer>
      )}
    </>
  );
}

export default observer(MenuSelectedProducts);
