import { action, makeObservable, observable } from "mobx";
import { onValue, ref, set } from "firebase/database";
import { database } from "../../configs";
import { getUserId } from "../../helpers";

export default class Settings {
  mainStore;

  lang = null;
  data = null;

  constructor(mainStore) {
    makeObservable(this, {
      data: observable,
      setData: action,
      lang: observable,
      setLang: action,
    });

    this.mainStore = mainStore;
  }

  setLang(value) {
    this.lang = value;
  }

  async index() {
    const userId = await getUserId();
    const refValue = ref(database, `${userId}/Settings`);

    await onValue(refValue, (data) => {
      this.setData(data.val());
    });
  }

  async createUserSettings(userId, country) {
    try {
      await set(ref(database, `${userId}/Settings`), { country });
    } catch (error) {
      window.console.error("Error saving country:", error);
    }
  }

  setData(value) {
    this.data = value || null;
  }
}
